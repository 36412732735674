import React, { useRef, useState, useCallback } from "react";
import PropTypes, { string } from "prop-types";
import { Form, Input, Button, Row, Checkbox, Col, Typography } from "antd";
import Cookies from "js-cookie";
import axios from "axios";

import chevron from "../../images/svg/chevron_left.svg";

const { Title } = Typography;

const LPLeadForm = (props) => {
  const {
    leadType,
    header,
    subHeader,
    formId,
    phoneNumber,
    phoneText,
    formCta,
    thankYouPath,
  } = props;
  const form = useRef(null);
  const [loading, setLoading] = useState(false);
  const [textMessages, setTextMessages] = useState(false);
  const emailValidation = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const phoneValidation = new RegExp(
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  );

  const onTextMessagesToggle = useCallback(() =>
    setTextMessages(() => !textMessages)
  );

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      fields: [
        {
          name: "firstname",
          value: values.name,
        },
        {
          name: "email",
          value: values.email,
        },
        {
          name: "phone",
          value: values.phone,
        },
        {
          name: "company",
          value: values.company,
        },
        // {
        //   name: 'city',
        //   value: values.location,
        // },
        {
          name: "text_messages",
          value: textMessages,
        },
        {
          name: "gclid",
          value: Cookies.get("gclid") || "",
        },
        {
          name: "utm_source",
          value: Cookies.get("utmSource") || "organic",
        },
        {
          name: "utm_medium",
          value: Cookies.get("utmMedium") || "",
        },
        {
          name: "utm_campaign",
          value: Cookies.get("utmCampaign") || "",
        },
        {
          name: "utm_content",
          value: Cookies.get("utmContent") || "",
        },
        {
          name: "utm_term",
          value: Cookies.get("utmTerm") || "",
        },
      ],
      context: {
        hutk: Cookies.get("hubspotutk") || undefined,
        pageUri: window.location.href,
        pageName: document.title,
      },
    };
    axios({
      method: "post",
      url: `https://api.hsforms.com/submissions/v3/integration/submit/2970821/${formId}`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(formData),
    })
      .then(() => {
        if (window.sessionStorage.getItem("Name")) {
          window.sessionStorage.removeItem("Name", values.name);
          window.sessionStorage.setItem("Name", values.name);
        } else {
          window.sessionStorage.setItem("Name", values.name);
        }
        if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
          // eslint-disable-next-line no-undef
          dataLayer.push({ event: `${leadType}-lead` });
        }
      })
      .then(() => {
        window.location.pathname = thankYouPath;
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.errors[0].errorType.toString() ===
            "INVALID_EMAIL"
          ) {
            setLoading(false);
            // eslint-disable-next-line no-alert
            return alert(
              `Looks like the email ${values.email} can not be validated.
                Please use a valid email.`
            );
          }
        } else if (error.request) {
          // eslint-disable-next-line no-console
          console.log(error.request);
        } else {
          // eslint-disable-next-line no-console
          console.log("Error", error.message);
        }
      });
  };
  return (
    <section className="lead-form-wrapper is-landing" ref={form}>
      <form
        name="Lead Demo Form"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="name" />
        <input type="text" name="email" />
        <input type="text" name="company" />
        <input type="text" name="phone" />
        <input type="text" name="textMessages" />
      </form>
      <Row className="header-form-wrapper">
        <Col xs={24}>
          <Title level={1}>
            {header}
            <span className="blue_dot">.</span>
          </Title>
        </Col>
        <Col xs={24}>
          <p style={{ fontSize: 16 }}>
            {subHeader}
            <a style={{ fontSize: 16, fontWeight: 800 }} href={phoneNumber}>
              {" "}
              {phoneText}
            </a>
          </p>
        </Col>
      </Row>

      <Form
        name="Lead Form"
        className="lead-form in-lp"
        layout="vertical"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        {/* Name */}
        <input type="hidden" name="Learn more" value="contact" />
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your name",
                  min: 2,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. John Doe" />
            </Form.Item>
          </Col>
          {/* Email */}
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email",
                  pattern: emailValidation,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. john@mybusiness.com" />
            </Form.Item>
          </Col>
        </Row>
        {/* Business Name */}
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Business name"
              name="company"
              rules={[
                {
                  required: true,
                  message: "Please enter your business name",
                  min: 3,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. MyBusiness Inc. " />
            </Form.Item>
          </Col>
          {/* Phone */}
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Phone number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please enter your phone",
                  pattern: phoneValidation,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. (555) 555-1243 " />
            </Form.Item>
          </Col>
        </Row>
        {/* Location */}
        <Row>
          {/* <Col xs={24} sm={24} md={12}>
            <Form.Item
              label="Business Location"
              name="location"
              rules={[
                {
                  required: true,
                  message: 'Please enter your business location',
                  min: 3,
                },
              ]}
            >
              <Input size="large" placeholder="e.g. San Francisco, CA" />
            </Form.Item>
          </Col> */}
          <Col xs={24}>
            <Form.Item name="textMessages">
              <Checkbox checked={textMessages} onChange={onTextMessagesToggle}>
                I agree to receive text message updates
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {/* Submit */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="cta-primary"
            style={{ marginTop: 20 }}
            loading={loading}
            size="small"
          >
            {formCta}
            <img
              src={chevron}
              alt="chevron icon"
              style={{ margin: "5px 5px 2px 10px" }}
            />
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};

LPLeadForm.propTypes = {
  formId: PropTypes.string.isRequired,
  leadType: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  phoneText: PropTypes.string,
  thankYouPath: PropTypes.string,
  formCta: string,
};
LPLeadForm.defaultProps = {
  phoneNumber: "",
  phoneText: "",
  thankYouPath: "/thank-you",
  formCta: "Learn more",
};

export default LPLeadForm;
